<template>
  <form @submit.prevent="save">
    <div class="nk-block">
      <div class="nk-block-head">
        <h5 class="title">Actas Administrativas</h5>
        <p></p>
      </div><!-- .nk-block-head -->
      <div v-if="!actas.length" class="alert alert-info">
        <p>Aún no se ha añadido ningún registro, da clic en <b>Agregar Acta</b> para añadir uno.</p>
      </div>
      <div v-for="acta in actas" :key="acta.id_acta" class="row">
        <div class="col-12 d-flex align-items-end justify-content-end">
          <button type="button" class="btn btn-outline-danger" @click.prevent="remove(acta)">Eliminar registro</button>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Motivo del Acta</label>
            <input type="text" class="form-control" v-model.lazy="acta.motivo">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Fecha</label>
            <input type="date" class="form-control" v-model.lazy="acta.fecha">
          </div>
        </div>
        <div class="col-sm-6 mt-3">
          <div class="form-group">
            <label class="form-label">Acta</label>
            <Uploader :preFiles="$filters.getFiles(acta.f_acta?.id_archivo)" :server="$filters.getServer(model)" v-model:file="acta.f_acta"></Uploader>
          </div>
        </div>
      </div>
      <div class="w-100 text-right">
        <button type="button" class="mt-2 btn btn-lg btn-info" @click.prevent="add">Agregar Acta</button>
      </div>
    </div><!-- .nk-block -->
  </form>
</template>

<script>
import {useApi} from "@/use/useApi";
import {reactive, toRefs, defineComponent, computed, watch} from 'vue';
import { useRouter } from 'vue-router';
import Swal from "sweetalert2";
import Uploader from "@/components/globales/Uploader";
const { v4: uuidv4 } = require('uuid');

export default defineComponent({
  name: "ActasAdministrativasEmpleado",
  props: ['empleado'],
  components: {
    Uploader
  },
  setup(params) {
    //Data
    const { loading, make } = useApi();
    const router = useRouter();

    const model = computed(()=>{
      return params.empleado;
    });

    const state = reactive({
      actas: [],
      inited: false
    });

    //Methods
    async function get(){
      loading.message = 'Obteniendo información';
      let {data} = await make('empleados/actas-administrativas/get', {id_empleado: model.value.id_empleado});
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
        router.go();
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        } else {
          state.actas = data.actas;
        }
      }
    }

    async function save(){
      loading.message = 'Actualizando información';
      //Save
      let {data} = await make('empleados/actas-administrativas/edit', {model: state.actas, id_empleado: model.value.id_empleado});
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        }
      }
    }

    function add() {
      state.actas.push({
        id_acta: uuidv4(),
        motivo: '',
        fecha: null,
        f_acta: {
          id_archivo: false,
          estatus: 'Pendiente',
          url: '#',
          autorizo: '',
          subio: '',
          nombre: ''
        }
      });
    }

    function remove(acta){
      state.actas = state.actas.filter(_acta => _acta.id_acta !== acta.id_acta);
    }

    watch(() => state.actas, () => {
      if(state.inited){
        save();
      } else {
        state.inited = true;
      }
    }, {deep: true});

    get();

    return {model, ...toRefs(state), loading, save, get, add, remove};
  }
});
</script>

<style scoped>
  textarea.form-control {
    min-height: 75px;
  }
  .form-label {
    text-transform: uppercase;
  }
</style>