<template>
  <form @submit.prevent="save">
    <div class="nk-block">
      <div class="nk-block-head">
        <h5 class="title">Documentación</h5>
        <p>Archivos varios como acta de nacimiento, CV, etc.</p>
      </div><!-- .nk-block-head -->
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Lugar de Nacimiento</label>
            <select-estado v-model:estado="data.estado_nacimiento" class="mb-2"></select-estado>
            <select-municipio :estado="data.estado_nacimiento" v-model:municipio="data.municipio_nacimiento" class="mb-2"></select-municipio>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Acta de Nacimiento</label>
            <Uploader :preFiles="$filters.getFiles(data.f_acta_nacimiento?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_acta_nacimiento"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Solicitud / C.V. de Empleo Requisitada</label>
            <Uploader :preFiles="$filters.getFiles(data.f_solicitud_empleo?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_solicitud_empleo"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">INE</label>
            <Uploader :preFiles="$filters.getFiles(data.f_ine?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_ine"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">CURP</label>
            <input type="text" class="form-control" v-model.lazy="data.curp">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">CURP</label>
            <Uploader :preFiles="$filters.getFiles(data.f_curp?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_curp"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">RFC</label>
            <input type="text" class="form-control" v-model.lazy="data.rfc">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">RFC</label>
            <Uploader :preFiles="$filters.getFiles(data.f_rfc?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_rfc"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">N° IMSS</label>
            <input type="text" class="form-control" v-model.lazy="data.no_imss">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">N° IMSS</label>
            <Uploader :preFiles="$filters.getFiles(data.f_no_imss?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_no_imss"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Domicilio</label>
            <textarea class="form-control" v-model.lazy="data.domicilio"></textarea>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Comprobante de Domicilio</label>
            <Uploader :preFiles="$filters.getFiles(data.f_comprobante_domicilio?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_comprobante_domicilio"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">CARTA DE RECOMENDACIÓN (CON TELEFONO) DEL ÚLTIMO EMPLEO</label>
            <Uploader :preFiles="$filters.getFiles(data.f_carta_recomendacion?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_carta_recomendacion"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">AVISO DE RETENCION DEL INFONAVIT (SOLO EN CASO DE TIENEN CREDITO)</label>
            <Uploader :preFiles="$filters.getFiles(data.f_aviso_retencion_infonavit?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_aviso_retencion_infonavit"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Último grado de estudios</label>
            <input class="form-control" v-model.lazy="data.ultimo_grado_estudios">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">ÚLTIMA CONSTANCIA DE ESTUDIOS</label>
            <Uploader :preFiles="$filters.getFiles(data.f_ultimo_grado_estudios?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_ultimo_grado_estudios"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">CARTA DE NO ANTECEDENTES PENALES</label>
            <Uploader :preFiles="$filters.getFiles(data.f_carta_no_antecedentes?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_carta_no_antecedentes"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">CARTILLA MILITAR</label>
            <Uploader :preFiles="$filters.getFiles(data.f_cartilla_militar?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_cartilla_militar"></Uploader>
          </div>
        </div>
        <div class="col-sm-6 mt-3">
          <div class="form-group">
            <label class="form-label">FOTOGRAFÍA TARJETA</label>
            <Uploader :preFiles="$filters.getFiles(data.f_tarjeta_banco?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_tarjeta_banco"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Banco</label>
            <input class="form-control" v-model.lazy="data.banco">
          </div>
        </div>
        <div class="col-sm-6 mt-3">
          <div class="form-group">
            <label class="form-label">FORMATO DE ALTA DE CUENTA BANCARIA</label>
            <Uploader :preFiles="$filters.getFiles(data.f_alta_banco?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_alta_banco"></Uploader>
          </div>
        </div>
        <div class="col-sm-6 mt-3">
          <div class="form-group">
            <label class="form-label">DOS REFERENCIA FAMILIARES (CON TELÉFONO)</label>
            <textarea class="form-control" v-model.lazy="data.referencias"></textarea>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">LICENCIA DE CONDUCIR (SOLO PERSONAL CON ACCESO A VEHICULO DE LA EMPRESA)</label>
            <Uploader :preFiles="$filters.getFiles(data.f_licencia_conducir?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_licencia_conducir"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">FOTOGRAFIA A COLOR (DIGITAL)</label>
            <Uploader :preFiles="$filters.getFiles(data.f_fotografia?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_fotografia"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">CERTIFICADO MÉDICO CON CÉDULA PROFESIONAL</label>
            <Uploader :preFiles="$filters.getFiles(data.f_certificado_medico?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_certificado_medico"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">ESTUDIO SOCIOECONOMICO ENTREGADO POR GASOIL</label>
            <Uploader :preFiles="$filters.getFiles(data.f_estudio_socioeconomico?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_estudio_socioeconomico"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">POLIZA DE SEGURO DE VIDA</label>
            <Uploader :preFiles="$filters.getFiles(data.f_poliza_seguro_vida?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_poliza_seguro_vida"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">ANTIDOPING</label>
            <Uploader :preFiles="$filters.getFiles(data.f_antidoping?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_antidoping"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">CEDULA CUIP</label>
            <Uploader :preFiles="$filters.getFiles(data.f_cedula_cuip?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_cedula_cuip"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">EXAMEN PSICOLÓGICO</label>
            <Uploader :preFiles="$filters.getFiles(data.f_examen_psicologico?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_examen_psicologico"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">EXAMEN PSICOMÉTRICO</label>
            <Uploader :preFiles="$filters.getFiles(data.f_examen_psicometrico?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_examen_psicometrico"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">ALTA DEL IMSS</label>
            <Uploader :preFiles="$filters.getFiles(data.f_alta_imss?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_alta_imss"></Uploader>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">RETENCIÓN DE FONACOT</label>
            <Uploader :preFiles="$filters.getFiles(data.f_retencion_fonacot?.id_archivo)" :server="$filters.getServer(model)" v-model:file="data.f_retencion_fonacot"></Uploader>
          </div>
        </div>
      </div>
    </div><!-- .nk-block -->
  </form>
</template>

<script>
import {useApi} from "@/use/useApi";
import { reactive, toRefs, defineComponent, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import Swal from "sweetalert2";
import Uploader from "@/components/globales/Uploader";
import SelectEstado from "@/components/globales/SelectEstado";
import SelectMunicipio from "@/components/globales/SelectMunicipio";

export default defineComponent({
  name: "DocumentacionEmpleado",
  components: {
    SelectMunicipio,
    SelectEstado,
    Uploader
  },
  props: ['empleado'],
  setup(params) {
    //Data
    const { loading, make } = useApi();
    const router = useRouter();

    const model = computed(()=>{
      return params.empleado;
    });

    const state = reactive({
      data: {},
      inited: false
    });

    //Methods
    async function get(){
      loading.message = 'Obteniendo información';
      let {data} = await make('empleados/documentacion/get', {id_empleado: model.value.id_empleado});
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
        router.go(0);
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        } else {
          state.data = data.model;
        }
      }
    }

    async function save(){
      loading.message = 'Actualizando información';
      //Save
      let {data} = await make('empleados/documentacion/edit', {model: state.data});
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error').then(() => {
            router.go(0);
          });
        }
      }
    }

    watch(() => state.data, () => {
      if(state.inited){
        save();
      } else {
        state.inited = true;
      }
    }, {deep: true});

    get();

    return {model, ...toRefs(state), loading, save, get};
  }
});
</script>

<style scoped>
  textarea.form-control {
    min-height: 75px;
  }
  .form-label {
    text-transform: uppercase;
  }
</style>