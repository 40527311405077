<template>
  <form @submit.prevent="save">
    <div class="nk-block">
      <div class="nk-block-head">
        <h5 class="title">Evaluaciones de Desempeño</h5>
        <p></p>
      </div><!-- .nk-block-head -->
      <div v-if="!evaluaciones.length" class="alert alert-info">
        <p>Aún no se ha añadido ningún registro, da clic en <b>Agregar Evaluación</b> para añadir uno.</p>
      </div>
      <div v-for="evaluacion in evaluaciones" :key="evaluacion.id_evaluacion" class="row">
        <div class="col-12 d-flex align-items-end justify-content-end">
          <button type="button" class="btn btn-outline-danger" @click.prevent="remove(evaluacion)">Eliminar registro</button>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Nombre de la Evaluación</label>
            <input type="text" class="form-control" v-model.lazy="evaluacion.nombre">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Fecha de la Evaluación</label>
            <input type="date" class="form-control" v-model.lazy="evaluacion.fecha">
          </div>
        </div>
        <div class="col-sm-6 mt-3">
          <div class="form-group">
            <label class="form-label">Certificado</label>
            <Uploader :preFiles="$filters.getFiles(evaluacion.f_certificado?.id_archivo)" :server="$filters.getServer(model)" v-model:file="evaluacion.f_certificado"></Uploader>
          </div>
        </div>
      </div>
      <div class="w-100 text-right">
        <button type="button" class="mt-2 btn btn-lg btn-info" @click.prevent="add">Agregar Evaluación</button>
      </div>
    </div><!-- .nk-block -->
  </form>
</template>

<script>
import {useApi} from "@/use/useApi";
import {reactive, toRefs, defineComponent, computed, watch} from 'vue';
import { useRouter } from 'vue-router';
import Swal from "sweetalert2";
import Uploader from "@/components/globales/Uploader";
const { v4: uuidv4 } = require('uuid');

export default defineComponent({
  name: "EvaluacionesDesempenoEmpleado",
  props: ['empleado'],
  components: {
    Uploader
  },
  setup(params) {
    //Data
    const { loading, make } = useApi();
    const router = useRouter();

    const model = computed(()=>{
      return params.empleado;
    });

    const state = reactive({
      evaluaciones: [],
      inited: false
    });

    //Methods
    async function get(){
      loading.message = 'Obteniendo información';
      let {data} = await make('empleados/evaluaciones/get', {id_empleado: model.value.id_empleado});
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
        router.go();
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        } else {
          state.evaluaciones = data.evaluaciones;
        }
      }
    }

    async function save(){
      loading.message = 'Actualizando información';
      //Save
      let {data} = await make('empleados/evaluaciones/edit', {model: state.evaluaciones, id_empleado: model.value.id_empleado});
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        }
      }
    }

    function add() {
      state.evaluaciones.push({
        id_evaluacion: uuidv4(),
        nombre: '',
        fecha: null,
        f_certificado: {
          id_archivo: false,
          estatus: 'Pendiente',
          url: '#',
          autorizo: '',
          subio: '',
          nombre: ''
        }
      });
    }

    function remove(evaluacion){
      state.evaluaciones = state.evaluaciones.filter(_evaluacion => _evaluacion.id_evaluacion !== evaluacion.id_evaluacion);
    }

    watch(() => state.evaluaciones, () => {
      if(state.inited){
        save();
      } else {
        state.inited = true;
      }
    }, {deep: true});

    get();

    return {model, ...toRefs(state), loading, save, get, add, remove};
  }
});
</script>

<style scoped>
  textarea.form-control {
    min-height: 75px;
  }
  .form-label {
    text-transform: uppercase;
  }
</style>