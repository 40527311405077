<template>
  <form @submit.prevent="save">
    <div class="nk-block">
      <div class="nk-block-head">
        <h5 class="title">Tallas Uniformes</h5>
        <p></p>
      </div><!-- .nk-block-head -->
      <div class="row">
        <div class="col-sm-6 mb-3">
          <div class="form-group">
            <label class="form-label">Camisa</label>
            <select class="form-control" v-model.lazy="data.camisa">
              <option v-for="talla in tallas.camisa" :key="talla" :value="talla">{{talla}}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Pantalón</label>
            <input type="text" class="form-control" v-model.lazy="data.pantalon">
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="form-group">
            <label class="form-label">Zapatos</label>
            <input type="text" class="form-control" v-model.lazy="data.zapatos">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Chaleco</label>
            <select class="form-control" v-model.lazy="data.chaleco">
              <option v-for="talla in tallas.chaleco" :key="talla" :value="talla">{{talla}}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="form-group">
            <label class="form-label">Chamarra</label>
            <select class="form-control" v-model.lazy="data.chamarra">
              <option v-for="talla in tallas.chamarra" :key="talla" :value="talla">{{talla}}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Traje Saco</label>
            <select class="form-control" v-model.lazy="data.traje_saco">
              <option v-for="talla in tallas.traje_saco" :key="talla" :value="talla">{{talla}}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Traje Pantalón</label>
            <input type="text" class="form-control" v-model.lazy="data.traje_pantalon">
          </div>
        </div>
      </div>
    </div><!-- .nk-block -->
  </form>
</template>

<script>
import {useApi} from "@/use/useApi";
import {reactive, toRefs, defineComponent, computed, watch} from 'vue';
import { useRouter } from 'vue-router';
import Swal from "sweetalert2";
export default defineComponent({
  name: "TallasEmpleado",
  props: ['empleado'],
  setup(params) {
    //Data
    const { loading, make } = useApi();
    const router = useRouter();

    const model = computed(()=>{
      return params.empleado;
    });

    const state = reactive({
      data: {
        id_empleado: 0,
        camisa: '',
        pantalon: '',
        zapatos: '',
        chaleco: '',
        chamarra: '',
        traje_saco: '',
        traje_pantalon: '',
      },
      tallas: {
        camisa: ['XS','S','M','L','XL','XXL'],
        chaleco: ['XS','S','M','L','XL','XXL'],
        chamarra: ['XS','S','M','L','XL','XXL'],
        traje_saco: ['XS','S','M','L','XL','XXL']
      },
      inited: false
    });

    //Methods
    async function get(){
      loading.message = 'Obteniendo información';
      let {data} = await make('empleados/tallas-uniformes/get', {id_empleado: model.value.id_empleado});
      if(data === null){
        router.go();
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          router.go();
          Swal.fire('Error', data.message, 'error');
        } else {
          state.data = data.model;
        }
      }
    }

    async function save(){
      loading.message = 'Actualizando información';
      //Save
      let {data} = await make('empleados/tallas-uniformes/edit', {model: state.data});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        }
      }
    }

    watch(() => state.data, () => {
      if(state.inited){
        save();
      } else {
        state.inited = true;
      }
    }, {deep: true});

    get();

    return {model, ...toRefs(state), loading, save};
  }
});
</script>

<style scoped>
  textarea.form-control {
    min-height: 75px;
  }
  .form-label {
    text-transform: uppercase;
  }
</style>