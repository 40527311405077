<template>
  <!--
  <div v-if="isAdmin && model.id_archivo && model.estatus === 'Pendiente'" class="actions-archivo d-flex justify-content-between mb-2">
    <button class="btn btn-danger" @click="rechazar">Rechazar</button>
    <button class="btn btn-success" @click="autorizar">Autorizar</button>
  </div>
  -->
  <div ref="cont" class="filepond-container"></div>

  <div v-if="model.id_archivo" class="estatus-archivo d-flex justify-content-between">
    <!--<p><strong>Estatus:</strong> <span :class="model.estatus">{{model.estatus}}</span></p> -->
    <p><a :href="model.url" download @click.prevent="downloadFile(model.url, model.nombre)">Descargar Archivo</a></p>
    <!-- <p v-if="model.estatus === 'Autorizado'"><strong>Autorizó:</strong> <span>{{model.autorizo}}</span></p> -->
    <p><strong>Subido por:</strong> <span>{{model.subio}}</span></p>
  </div>
</template>

<script type="ts">
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import {defineComponent, onMounted, ref, computed, watch} from 'vue';
import * as FilePond from 'filepond';
import es_ES from 'filepond/locale/es-es.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import {storage} from "@/providers/api";
import download from "@/filters/download";
import useAuth from "@/providers/auth";
import {API_URL} from "@/providers/config";
const { v4: uuidv4 } = require('uuid');

export default defineComponent({
  name: 'Uploader',
  props: {
    server: Object,
    maxFiles: Number,
    allowMultiple: Boolean,
    preFiles: [Array, String],
    file: {
      type: Object,
      default: () => {
        return {
          id_archivo: false,
          estatus: 'Pendiente',
          url: '#',
          autorizo: '',
          subio: '',
          nombre: ''
        }
      }
    }
  },
  setup(props, {emit}) {

    const { isAdmin, user } = useAuth();
    const cont = ref(null);
    const model = computed(() => props.file);
    let files_cache = [];

    let server = (props.server) ? props.server : {
      url: API_URL + 'empleados/test-files'
    };

    server.headers = {
      'Authorization': 'Bearer ' + storage.get('token')
    };

    FilePond.setOptions(es_ES);

    FilePond.registerPlugin(
        FilePondPluginImagePreview,
        FilePondPluginImageExifOrientation
    );

    const pond = FilePond.create({
      name: uuidv4(),
      ...props,
      server,
      credits: false
    });

    function toJSON(val){
      return JSON.stringify(val);
    }

    watch(() => props.preFiles, () => {
      processFiles();
    });

    function processFiles(){
      if(toJSON(files_cache) != toJSON(props.preFiles)){
        files_cache = props.preFiles;
        pond.removeFiles();
        pond.addFiles(getFiles(props.preFiles));
      }
    }

    function getFiles(preFiles){
      let files = [];
      if(preFiles && typeof preFiles === 'object'){
        preFiles.forEach(file => {
          if(file){
            files.push({source: file, options: {type: 'local'}})
          }
        });
      }
      return files;
    }

    //Map all Events
    let events = ['init','warning','error','addfilestart','addfileprogress','addfile','processfilestart','processfileprogress','processfileabort','processfilerevert','processfile','removefile','updatefiles','reorderfiles'];
    events.forEach(event => {
      pond.on(event, (...args) => {
        emit(event, ...args);
      });
    });

    //Update Model
    pond.on('processfile', (error, file) => {
      if(file.serverId.includes('{')){
        emit('update:file', JSON.parse(file.serverId));
      }
    });

    pond.on('removefile', (a,b) => {
      if(!b.serverId){
        let default_file = {
          'id_archivo': '',
          'estatus': 'Pendiente',
          'url': '#',
          'subio': '',
          'autorizo': '',
          'nombre': ''
        };
        emit('update:file', default_file);
      }
    });

    onMounted(() => {
      cont.value.append(pond.element);
      processFiles();
    });

    function autorizar(){
      let file = {...model.value};
      file.estatus = 'Autorizado';
      const current_user = user.value;
      file.autorizo = current_user.name + ' ' + current_user.lastname;
      emit('update:file', file);
    }

    function rechazar(){
      let file = {...model.value};
      file.estatus = 'Rechazado';
      const current_user = user.value;
      file.autorizo = current_user.name + ' ' + current_user.lastname;
      emit('update:file', file);
    }

    function downloadFile(url, nombre){
      download(url, nombre);
    }

    return { cont, model, isAdmin, autorizar, rechazar, downloadFile };
  }
});
</script>

<style scoped>
  .Autorizado {
    color: var(--success);
  }
  .Pendiente {
    color: var(--warning)
  }
  .Rechazado {
    color: var(--danger);
  }
</style>