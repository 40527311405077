<template>
  <form @submit.prevent="save">
    <div class="nk-block">
      <div class="nk-block-head">
        <h5 class="title">Información Médica</h5>
        <p></p>
      </div><!-- .nk-block-head -->
      <div class="row">
        <div class="col-sm-6 mb-3">
          <div class="form-group">
            <label class="form-label">Tipo de sangre</label>
            <input type="text" class="form-control" v-model.lazy="data.tipo_sangre">
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="form-group">
            <label class="form-label">Enfermedades Crónicas</label>
            <textarea class="form-control" v-model.lazy="data.enfermedades_cronicas" maxlength="1000"></textarea>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="form-group">
            <label class="form-label">Peso</label>
            <input type="text" class="form-control" v-model.lazy="data.peso">
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <div class="form-group">
            <label class="form-label">Estatura</label>
            <input type="text" class="form-control" v-model.lazy="data.estatura">
          </div>
        </div>
      </div>
    </div><!-- .nk-block -->
  </form>
  <form @submit.prevent="saveAntidopings" class="mt-3">
    <div class="nk-block">
      <div class="nk-block-head">
        <h5 class="title">Antidopings</h5>
        <p></p>
      </div><!-- .nk-block-head -->
      <div v-if="!antidopings.length" class="alert alert-info">
        <p>Aún no se ha añadido ningún certificado, da clic en <b>Agregar Certificado</b> para añadir uno.</p>
      </div>
      <div v-for="antidopoing in antidopings" :key="antidopoing.id_antidopoing" class="row">
        <div class="col-12 d-flex align-items-end justify-content-end">
          <button type="button" class="btn btn-outline-danger" @click.prevent="removeAntidopings(antidopoing)">Eliminar registro</button>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Nombre</label>
            <input type="text" class="form-control" v-model.lazy="antidopoing.nombre">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Fecha</label>
            <input type="date" class="form-control" v-model.lazy="antidopoing.fecha">
          </div>
        </div>
        <div class="col-sm-6 mt-3">
          <div class="form-group">
            <label class="form-label">Comprobante</label>
            <Uploader :preFiles="$filters.getFiles(antidopoing.f_certificado?.id_archivo)" :server="$filters.getServer(model)" v-model:file="antidopoing.f_certificado"></Uploader>
          </div>
        </div>
      </div>
      <div class="w-100 text-right">
        <button type="button" class="mt-2 btn btn-lg btn-info" @click.prevent="addAntidopings">Agregar Certificado</button>
      </div>
    </div><!-- .nk-block -->
  </form>
  <form @submit.prevent="saveCertificados" class="mt-3">
    <div class="nk-block">
      <div class="nk-block-head">
        <h5 class="title">Certificados Médicos</h5>
        <p></p>
      </div><!-- .nk-block-head -->
      <div v-if="!certificados.length" class="alert alert-info">
        <p>Aún no se ha añadido ningún certificado, da clic en <b>Agregar Certificado</b> para añadir uno.</p>
      </div>
      <div v-for="certificado in certificados" :key="certificado.id_certificado" class="row">
        <div class="col-12 d-flex align-items-end justify-content-end">
          <button type="button" class="btn btn-outline-danger" @click.prevent="removeCertificados(certificado)">Eliminar registro</button>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Nombre</label>
            <input type="text" class="form-control" v-model.lazy="certificado.nombre">
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="form-label">Fecha</label>
            <input type="date" class="form-control" v-model.lazy="certificado.fecha">
          </div>
        </div>
        <div class="col-sm-6 mt-3">
          <div class="form-group">
            <label class="form-label">Comprobante</label>
            <Uploader :preFiles="$filters.getFiles(certificado.f_certificado?.id_archivo)" :server="$filters.getServer(model)" v-model:file="certificado.f_certificado"></Uploader>
          </div>
        </div>
      </div>
      <div class="w-100 text-right">
        <button type="button" class="mt-2 btn btn-lg btn-info" @click.prevent="addCertificados">Agregar Certificado</button>
      </div>
    </div><!-- .nk-block -->
  </form>
</template>

<script>
import {useApi} from "@/use/useApi";
import {reactive, toRefs, defineComponent, computed, watch} from 'vue';
import { useRouter } from 'vue-router';
import Swal from "sweetalert2";
import Uploader from "@/components/globales/Uploader";
const { v4: uuidv4 } = require('uuid');

export default defineComponent({
  name: "MedicoEmpleado",
  components: {Uploader},
  props: ['empleado'],
  setup(params) {
    //Data
    const { loading, make } = useApi();
    const router = useRouter();

    const model = computed(()=>{
      return params.empleado;
    });

    const state = reactive({
      data: {
        id_empleado: 0,
        tipo_sangre: '',
        enfermedades_cronicas: '',
        peso: '',
        estatura: ''
      },
      antidopings: [],
      certificados: [],
      inited: false
    });

    //Methods
    async function getAntidopings(){
      let {data} = await make('empleados/medico/get-antidopings', {id_empleado: model.value.id_empleado});
      if(data === null){
        router.go();
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          router.go();
          Swal.fire('Error', data.message, 'error');
        } else {
          state.antidopings = data.antidopings;
        }
      }
    }

    async function getCertificados(){
      let {data} = await make('empleados/medico/get-certificados', {id_empleado: model.value.id_empleado});
      if(data === null){
        router.go();
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          router.go();
          Swal.fire('Error', data.message, 'error');
        } else {
          state.certificados = data.certificados;
        }
      }
    }

    async function get(){
      loading.message = 'Obteniendo información';
      let {data} = await make('empleados/medico/get', {id_empleado: model.value.id_empleado});
      if(data === null){
        router.go();
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          router.go();
          Swal.fire('Error', data.message, 'error');
        } else {
          state.data = data.model;
          getAntidopings();
          getCertificados();
        }
      }
    }

    async function save(){
      loading.message = 'Actualizando información';
      //Save
      let {data} = await make('empleados/medico/edit', {model: state.data});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        }
      }
    }

    watch(() => state.data, () => {
      if(state.inited){
        save();
      } else {
        state.inited = true;
      }
    }, {deep: true});

    async function saveAntidopings(){
      loading.message = 'Actualizando información';
      //Save
      let {data} = await make('empleados/medico/edit-antidopings', {model: state.antidopings, id_empleado: model.value.id_empleado});
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        }
      }
    }

    function addAntidopings() {
      state.antidopings.push({
        id_antidoping: uuidv4(),
        nombre: '',
        fecha: null,
        f_certificado: {
          id_archivo: false,
          estatus: 'Pendiente',
          url: '#',
          autorizo: '',
          subio: '',
          nombre: ''
        }
      });
    }

    function removeAntidopings(antidoping){
      state.antidopings = state.antidopings.filter(_antidoping => _antidoping.id_antidoping !== antidoping.id_antidoping);
    }

    watch(() => state.antidopings, () => {
      if(state.inited){
        saveAntidopings();
      } else {
        state.inited = true;
      }
    }, {deep: true});

    async function saveCertificados(){
      loading.message = 'Actualizando información';
      //Save
      let {data} = await make('empleados/medico/edit-certificados', {model: state.certificados, id_empleado: model.value.id_empleado});
      if(data === null){
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          await Swal.fire('Error', data.message, 'error');
        }
      }
    }

    function addCertificados() {
      state.certificados.push({
        id_certificado: uuidv4(),
        nombre: '',
        fecha: null,
        f_certificado: {
          id_archivo: false,
          estatus: 'Pendiente',
          url: '#',
          autorizo: '',
          subio: '',
          nombre: ''
        }
      });
    }

    function removeCertificados(certificado){
      state.certificados = state.certificados.filter(_certificado => _certificado.id_certificado !== certificado.id_certificado);
    }

    watch(() => state.certificados, () => {
      if(state.inited){
        saveCertificados();
      } else {
        state.inited = true;
      }
    }, {deep: true});

    get();

    return {model, ...toRefs(state), loading, save, saveAntidopings, addAntidopings, removeAntidopings, saveCertificados, addCertificados, removeCertificados};
  }
});
</script>

<style scoped>
  textarea.form-control {
    min-height: 75px;
  }
  .form-label {
    text-transform: uppercase;
  }
</style>